import axios from "axios";
import i18n from "../i18n";
/**
 * API call to get submission List
 * @param {*} setSubmissionList
 */
export const getSubmissionsList = async (
  setSubmissionList,
  setLoadingSubmission,
  setAlert,
  clearAlert
) => {
  try {
    setLoadingSubmission(true);
    clearAlert();
    const request = {
      url: `/submissions`,
      params: {
        vw: "brief",
        ps: 500,
      },
    };
    const response = await axios(request);
    if (response) {
      setSubmissionList(response.data.values);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoadingSubmission(false);
  }
};

export const getFacultyNames = async (submissionId, setFacultyNames) => {
  try {
    const request = {
      url: `/submissions/${submissionId}/faculty/faculty-names`,
    };

    const response = await axios(request);

    setFacultyNames(response.data);
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param {*} setData
 * @param {*} setTotalCount
 * @param {*} setIsLoading
 * @param {*} selectedSubmission
 * @param {*} grantsPreference
 * @param {*} history
 */
export const getGrantsDetails = async (
  setData,
  setTotalCount,
  setIsLoading,
  selectedSubmission,
  grantsPreference,
  history,
  setAlert,
  clearAlert,
  setMetaData,
  getSelectedSubmissionName
) => {
  const { filters, pagination, sort } = grantsPreference.grants;
  const {
    title,
    grantNumber,
    projectStartDateMin: projectStartDateFrom,
    projectStartDateMax: projectStartDateTo,
    projectEndDateMin: projectEndDateFrom,
    projectEndDateMax: projectEndDateTo,
    principal,
    grantRole,
  } = filters;
  const { page, pageSize } = pagination;
  const { orderBy, order } = sort;
  try {
    clearAlert();
    setIsLoading(true);

    let request = "";
    let response = "";
    if (selectedSubmission) {
      request = {
        url: `/submissions/${selectedSubmission}/grants`,
        params: {
          title,
          grantNumber,
          projectStartDateFrom,
          projectStartDateTo,
          projectEndDateFrom,
          projectEndDateTo,
          principal,
          grantRole,
          p: page + 1,
          ps: pageSize || 10,
          s: `${orderBy ? `${orderBy}` : `title`}:${
            order ? `${order}` : `asc`
          }`,
        },
      };
      response = await axios(request);

      setMetaData(response.data.meta);
      setData(response.data.values ?? []);
      setTotalCount(response.data.totalCount);
    } else {
      setMetaData(false);
      setData([]);
      setTotalCount(0);
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        i18n.t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
    setIsLoading(false);
  } finally {
    setIsLoading(false);
  }
};

/**
 *
 * @param {*} setTitleAwardList
 * @param {*} setProjectPeriodList
 * @param {*} setGrantPrincipal
 * @param {*} setGrantRoleList
 * @param {*} selectedSubmission
 */
export const getGrantsFilterOptionsValue = async (
  setTitleAwardList,
  setProjectPeriodList,
  selectedSubmission,
  history,
  setAlert,
  clearAlert,
  getSelectedSubmissionName
) => {
  const grantFiltersUrl = {
    grantTitleAwardNumberOptions: "grants/autocomplete",
    grantProjectPeriods: "grants/project-periods",
  };
  const requests = Object.values(grantFiltersUrl);
  try {
    clearAlert();
    var response = "";
    if (selectedSubmission) {
      response = await Promise.all(
        requests.map((request) =>
          axios({
            url: `/submissions/${selectedSubmission}/${request}`,
          }).then((response) => response)
        )
      );
    }

    const getFieldValues = (responseData) => {
      let valueArray = [];
      if (responseData.count > 0) {
        valueArray = responseData.values.map((filterVal) => filterVal.value);
      }
      return valueArray;
    };
    setTitleAwardList(getFieldValues(response[0].data));
    setProjectPeriodList(response[1].data);
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        i18n.t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  }
};

export const getGrantsById = async (
  submissionId,
  grantId,
  setGrant,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const request = {
      url: `/submissions/${submissionId}/grants/${grantId}`,
    };

    const response = await axios(request);
    if (response.data) {
      setGrant(response.data);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

export const addRemoveGrantAction = async (
  selectedSubmission,
  grantID,
  grantData,
  setResponseData,
  setAlert,
  clearAlert,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    clearAlert();
    if (grantID) {
      const request = {
        method: "patch",
        url: `/submissions/${selectedSubmission}/grants`,
        params: {
          submissionGrantId: grantID,
        },
        data: grantData,
      };

      const response = await axios(request);
      if (response) {
        setResponseData(response);
      }
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setIsLoading(false);
  }
};

/**
 * API call to Export CSV
 * @param {*} submission
 * @param {*} grantsPreference
 */
export const getGrantsExportData = async (submission, grantsPreference) => {
  try {
    const { filters, pagination, sort } = grantsPreference.grants;
    const {
      title,
      grantNumber,
      projectStartDateMin: projectStartDateFrom,
      projectStartDateMax: projectStartDateTo,
      projectEndDateMin: projectEndDateFrom,
      projectEndDateMax: projectEndDateTo,
      principal,
      grantRole,
    } = filters;
    const { page, pageSize } = pagination;
    const { orderBy, order } = sort;

    const request = {
      url: `/submissions/${submission}/grants`,
      params: {
        title,
        grantNumber,
        projectStartDateFrom,
        projectStartDateTo,
        projectEndDateFrom,
        projectEndDateTo,
        principal,
        grantRole,
        p: page + 1,
        ps: pageSize || 10000,
        s: `${orderBy ? `${orderBy}` : `title`}:${order ? `${order}` : `asc`}`,
      },
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
        "Content-type": "application/vnd.ms-excel",
      },
    };

    const response = await axios(request);

    if (response.data) {
      const currentDate = new Date();
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      const date = ("0" + currentDate.getDate()).slice(-2);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Export_Grants_${currentDate.getFullYear()}${month}${date}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {}
};

/**
 * PUT call to update grants
 */

export const updateGrantAction = async (
  selectedSubmission,
  grantID,
  requestData,
  setResPost,
  props,
  t,
  titleLabel,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    if (requestData) {
      const request = {
        method: "patch",
        url: `/submissions/${selectedSubmission}/grants/${grantID}`,
        data: requestData,
      };

      const response = await axios(request);
      setResPost(response);
      props.history.push({
        pathname: "/grants",
      });
      setAlert(
        "success",
        t("grant.notification.updateGrants", {
          title: titleLabel,
        }),
        true
      );
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};
