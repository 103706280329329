import axios from "axios";
import i18n from "../i18n";
import { normalizeText } from "../Utils/utils";
import { nanoid } from "nanoid";

import {
  OutcomeResultingDegreeTypeEnums,
  OutcomeSubsequentGrantRoleEnums,
} from "../constants";
import { SelectAllStates } from "./outcomesSelectedContext";
/**
 * Fetch the List of Assigned Submissions,
 * to be displayed in Submissions Autocomplete List
 */

export const setOutcomeSubmissions = async (
  setSubmissionList,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const request = {
      url: "/submissions",
      params: {
        vw: "brief",
        ps: 500,
      },
    };

    const response = await axios(request);
    if (response.data.count) {
      setSubmissionList(response.data.values);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

const retrieveGetOutcomesParams = (outcomesPreferences) => {
  const { filters, pagination, sort } = outcomesPreferences.values;
  const uniId = filters.traineeName?.value?.universityId;
  const facultyId = filters.facultyName?.value?.universityId;
  const traineeTypeCode = filters.traineeType;
  const {
    trainingStartDateFrom,
    trainingStartDateTo,
    trainingEndDateFrom,
    trainingEndDateTo,
    isActive,
  } = filters;
  const { page, pageSize } = pagination;
  const { order, orderBy } = sort;

  const orderParamPart = order || `asc`;
  let sortParams = orderBy || "";
  sortParams =
    sortParams &&
    sortParams
      .split(/,/)
      .map((s) => `${s}:${orderParamPart}`)
      .join(",");

  return {
    vw: "full",
    traineeUniversityId: uniId,
    facultyUniversityId: facultyId,
    traineeType: traineeTypeCode,
    trainingStartDateFrom,
    trainingStartDateTo,
    trainingEndDateFrom,
    trainingEndDateTo,
    isActive: isActive ? true : "",
    p: page + 1, // What if page is a string?
    ps: pageSize || 10,
    s: sortParams,
  };
};

/* New API Service level implementation */

export const getAllOutcomesList = async (
  setOutcomesList,
  setSummary,
  selectedSubmission,
  setLoading,
  setAlert,
  clearAlert,
  setError,
  outcomesPreferences,
  history,
  setMetaData,
  getSelectedSubmissionName
) => {
  try {
    setLoading(true);
    clearAlert();

    let request = "";
    let response = "";
    const requestParams = retrieveGetOutcomesParams(outcomesPreferences);

    if (selectedSubmission) {
      request = {
        url: `/submissions/${selectedSubmission}/outcomes`,
        params: requestParams,
      };

      response = await axios(request);
      const summary = { totalCount: 0, totalActiveCount: 0 };
      if (requestParams.isActive === true) {
        summary.totalActiveCount = response.data.totalCount;
      } else if (requestParams.isActive === false) {
        summary.totalActiveCount = 0;
      } else {
        const requestActive = {
          url: `/submissions/${selectedSubmission}/outcomes`,
          params: { ...requestParams, isActive: true },
        };

        const responseActive = await axios(requestActive);
        summary.totalActiveCount = responseActive.data.totalCount;
      }

      setMetaData(response.data.meta);
      setOutcomesList(response.data);
      setSummary({ ...summary, totalCount: response.data.totalCount });
    } else {
      setMetaData(false);
      setOutcomesList([]);
      setSummary({ totalCount: 0 });
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        i18n.t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * API call to Export CSV
 */
export const getOutcomeExportData = async (
  subId,
  context,
  totalCount,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const requestParams = retrieveGetOutcomesParams(context);
    // Create queryString to be appended to URL Path
    clearAlert();
    let request = "";
    let response = "";

    if (subId != null) {
      request = {
        url: `/submissions/${subId}/outcomes`,
        params: {
          ...requestParams,
          p: 1,
          ps: totalCount || 500,
        },
        responseType: "blob",
        headers: {
          Accept: "application/vnd.ms-excel",
          "Content-type": "application/vnd.ms-excel",
        },
      };

      response = await axios(request);

      if (response.data) {
        const currentDate = new Date();
        const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
        const date = ("0" + currentDate.getDate()).slice(-2);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Export_Outcomes_${currentDate.getFullYear()}${month}${date}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

export const getOutcomesFilters = async (
  setTraineeDates,
  setTraineeType,
  selectedSubmission,
  setAlert,
  clearAlert,
  getSelectedSubmissionName
) => {
  try {
    clearAlert();

    if (selectedSubmission) {
      const responses = await axios({
        url: `/submissions/${selectedSubmission}/outcomes/filters`,
      });

      const { outcomeTraineeTypes, outcomeTrainingPeriod } = responses.data;

      setTraineeDates(outcomeTrainingPeriod);
      setTraineeType(outcomeTraineeTypes.values ?? []);
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        i18n.t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  }
};

export const postAddTraineeToOutcome = async (
  setSnackBar,
  selectedSubmission,
  addTrainee,
  setRefreshedOutcomeID,
  setOutcomesPreferences,
  outcomesPreferences,
  setAlert,
  clearAlert,
  setLoading,
  setKey
) => {
  try {
    clearAlert();
    if (addTrainee) {
      setLoading(true);
      const request = {
        method: "post",
        url: `/submissions/${selectedSubmission}/outcomes/trainee`,
        data: addTrainee,
      };
      const response = await axios(request);

      if (response && response.status === 201) {
        setSnackBar({
          show: true,
          message: "outcomes.mainView.addPastTraineeToOutcomeMessage",
        });

        setRefreshedOutcomeID(response.data.id);
        setOutcomesPreferences({
          ...outcomesPreferences,
          values: {
            ...outcomesPreferences.values,
            renderCount: outcomesPreferences.values.renderCount + 1,
          },
        });
      }
    }
  } catch (error) {
    if (
      error.status === 404 &&
      error.code === "RESOURCE_NOT_FOUND" &&
      error.response.data.detail.properties.some(
        (property) => property.resource === "SubmissionOutcome"
      )
    ) {
      setAlert("error", i18n.t(`outcomes.mainView.notFound`));
    } else if (
      error.status === 409 &&
      error.code === "RESOURCE_EXISTS" &&
      error.response.data.detail.properties.some(
        (property) => property.resource === "SubmissionOutcome"
      )
    ) {
      setAlert("error", i18n.t(`trainee.mainView.duplicateTrainee`));
    } else if (
      error.status === 400 &&
      error.code === "INVALID_VALUE" &&
      error.response.data.detail.properties.some(
        (property) => property.resource === "SubmissionOutcome"
      )
    ) {
      setAlert("error", i18n.t(`outcomes.mainView.ineligibleTrainee`));
    } else {
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
    setKey(`add-${new Date().toString()}`);
  }
};

/**
 * Returns Submission Outcome values to be Edited, for the Selected Submission Id
 **/

export const getOutcomeDetailsById = async (
  setOutcome,
  setLoading,
  outcomeId,
  submissionId,
  setAlert,
  clearAlert
) => {
  try {
    setLoading(true);
    clearAlert();

    const response = await axios({
      url: `/submissions/${submissionId}/outcomes/${outcomeId}`,
    });

    setOutcome({
      traineeLastName: response.data.traineeLastName,
      traineeFirstName: response.data.traineeFirstName,
      traineeMiddleName: response.data.traineeMiddleName,
      traineeEmail: response.data.traineeEmail,
      traineeType: response.data.traineeType,
      trainingStartDate: response.data.trainingStartDate,
      trainingEndDate: response.data.trainingEndDate,
      submissionOutcomeFaculty: response.data.submissionOutcomeFaculty,
      researchTopic: response.data.researchTopic,
      initialPositionSummary: response.data.initialPositionSummary,
      currentPositionSummary: response.data.currentPositionSummary,
      terminalDegrees:
        response.data.terminalDegrees?.map((terminalDegree) => ({
          ...terminalDegree,
          key: nanoid(),
        })) ?? [],
      trainingSupportYears:
        response.data.trainingSupportYears?.map((trainingSupportYear) => ({
          ...trainingSupportYear,
          key: nanoid(),
        })) ?? [],
      resultingDegrees:
        response.data.resultingDegrees?.map((resultingDegree) => ({
          degreeCode:
            resultingDegree.degreeCode !== "OTHER_D"
              ? resultingDegree.degreeCode
              : resultingDegree.otherDegree,
          degreeYear: resultingDegree.degreeYear,
          key: nanoid(),
        })) ?? [],
      subsequentGrants:
        response.data.subsequentGrants?.map((subsequentGrant) => ({
          grantName: subsequentGrant.grantName,
          roleCode:
            subsequentGrant.roleCode !== "OTHER_D"
              ? subsequentGrant.roleCode
              : subsequentGrant.otherRole,
          grantYear: subsequentGrant.grantYear,
          key: nanoid(),
        })) ?? [],
      _links: response.data._links,
    });
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/**
 * Returns Success/Error Response Data for the Update Submission Outcome API call
 *
 * @param {string} submissionId - Selected Submission ID
 * @param {string} outcomeId - ID of Outcome to be updated
 * @param {string} values  - Data to be Updated for the Outcome
 * @return Success/Error Response for the Update Outcome API call
 */

export const putOutcomeDetailsByIdUpdate = async (
  submissionId,
  outcomeId,
  values,
  props,
  setAlert,
  clearAlert
) => {
  const buildTrainingSupportYearRequest = (trainingSupportYears) => {
    return trainingSupportYears.map((trainingSupportYear, index) => {
      const result = {
        ...(trainingSupportYear.trainingSourceCode && {
          trainingSourceCode: trainingSupportYear.trainingSourceCode,
        }),
        ...(trainingSupportYear.nihHhsComponentAndActivity && {
          nihHhsComponentAndActivity:
            trainingSupportYear.nihHhsComponentAndActivity,
        }),
        trainingTypeCode: trainingSupportYear.trainingTypeCode,
        trainingYearNumber: index + 1,
      };
      return result;
    });
  };

  const buildResultingDegreesRequest = (resultingDegrees) => {
    return resultingDegrees.map((resultingDegree, index) => {
      const degreeCode = Object.keys(OutcomeResultingDegreeTypeEnums).includes(
        resultingDegree.degreeCode
      )
        ? resultingDegree.degreeCode
        : "OTHER_D";
      return {
        ...(resultingDegree.degreeYear && {
          degreeYear: resultingDegree.degreeYear,
        }),
        ...(degreeCode === "OTHER_D" && {
          otherDegree: resultingDegree.degreeCode,
        }),
        degreeCode: degreeCode,
      };
    });
  };

  const buildSubsequentGrantsRequest = (subsequentGrants) => {
    return subsequentGrants.map((grant, index) => {
      const roleCode = Object.keys(OutcomeSubsequentGrantRoleEnums).includes(
        grant.roleCode
      )
        ? grant.roleCode
        : "OTHER_D";
      return {
        grantName: grant.grantName,
        grantYear: grant.grantYear,
        ...(roleCode === "OTHER_D" && {
          otherRole: grant.roleCode,
        }),
        roleCode: roleCode,
      };
    });
  };

  const buildSummaryPositionRequest = (summaryPosition) => {
    return {
      ...(summaryPosition.department && {
        department: summaryPosition.department,
      }),
      ...(summaryPosition.institution && {
        institution: summaryPosition.institution,
      }),
      ...(summaryPosition.activity && {
        activity: summaryPosition.activity,
      }),
      ...(summaryPosition.workforceSector && {
        workforceSector: summaryPosition.workforceSector,
      }),
      position: summaryPosition.position,
    };
  };

  const outcomeRequest = {
    ...(values.initialPositionSummary.position && {
      initialPositionSummary: buildSummaryPositionRequest(
        values.initialPositionSummary
      ),
    }),
    ...(values.currentPositionSummary.position && {
      currentPositionSummary: buildSummaryPositionRequest(
        values.currentPositionSummary
      ),
    }),
    traineeLastName: normalizeText(values.traineeLastName, null),
    traineeFirstName: normalizeText(values.traineeFirstName, null),
    traineeMiddleName: normalizeText(values.traineeMiddleName, null),
    traineeEmail: normalizeText(values.traineeEmail, null),
    traineeType: values.traineeType,
    trainingStartDate: values.trainingStartDate,
    trainingEndDate: values.trainingEndDate,
    terminalDegrees: values.terminalDegrees,
    submissionOutcomeFaculty: values.submissionOutcomeFaculty.map(
      (faculty) => ({
        // TODO : Verify if univId is needed in the API call
        univId:
          faculty.value?.universityId || faculty.univId || faculty.universityId,
        universityId:
          faculty.value?.universityId || faculty.univId || faculty.universityId,
        ...((faculty.value?.sunetId || faculty.sunetId) && {
          sunetId: faculty.value?.sunetId || faculty.sunetId,
        }),
      })
    ),
    trainingSupportYears: buildTrainingSupportYearRequest(
      values.trainingSupportYears
    ),
    resultingDegrees: buildResultingDegreesRequest(values.resultingDegrees),
    researchTopic: normalizeText(values.researchTopic, null),
    subsequentGrants: buildSubsequentGrantsRequest(values.subsequentGrants),
  };

  try {
    clearAlert();
    const response = await axios.put(
      `/submissions/${submissionId}/outcomes/${outcomeId}`,
      outcomeRequest
    );

    // **** Redirect to /outcomes on Successful form Submit ****
    const alertMessage = i18n.t("outcomes.notification.updateOutcome", {
      traineeFullName: `"${response.data.traineeName.trim()}"`,
    });
    props.history.push({
      pathname: "/outcomes",
    });
    setAlert("success", alertMessage, true);
  } catch (error) {
    setAlert("error", error.message);
  }
};

/**
 * Patch call to remove or add outcome
 */
export const removeAddById = async (
  selectedSubmission,
  outcomeId,
  outcomeData,
  setSnackbar,
  setAlert,
  clearAlert,
  setLoading,
  outcomesPreferences,
  setOutcomesPreferences
) => {
  try {
    setLoading(true);
    clearAlert();
    const request = {
      method: "PATCH",
      url: `/submissions/${selectedSubmission}/outcomes/${outcomeId}`,
      data: { ...outcomeData, isActive: !outcomeData.isActive },
    };
    let response = await axios(request);
    outcomeData.isActive = response.data.isActive;
    setSnackbar(true);
    setOutcomesPreferences({
      ...outcomesPreferences,
      values: {
        ...outcomesPreferences.values,
        renderCount: outcomesPreferences.values.renderCount + 1,
      },
    });

    return response.status;
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const getOutcomeEmailInvitationRecipients = async (
  setOutcomeTraineeList,
  submissionId,
  setLoading,
  setAlert,
  clearAlert,
  outcomesFilters,
  outcomesSelected,
  SelectAllStates
) => {
  const uniId = outcomesFilters.traineeName?.value?.universityId;
  const facultyId = outcomesFilters.facultyName?.value?.universityId;
  const traineeTypeCode = outcomesFilters.traineeType;
  const {
    trainingStartDateFrom,
    trainingStartDateTo,
    trainingEndDateFrom,
    trainingEndDateTo,
  } = outcomesFilters;

  setLoading(true);

  try {
    clearAlert();

    if (submissionId != null) {
      const url = `/submissions/${submissionId}/outcomes`;
      const params = {
        traineeUniversityId: uniId,
        facultyUniversityId: facultyId,
        traineeType: traineeTypeCode,
        trainingStartDateFrom,
        trainingStartDateTo,
        trainingEndDateFrom,
        trainingEndDateTo,
        isActive: true,
      };

      let results = [];
      let page = 0;
      const pageSize = 500;

      const { ALL_SELECTED, NONE_SELECTED } = SelectAllStates;

      const traineeIdsSelected = Object.keys(
        outcomesSelected?.individualSelections ?? []
      );

      if (
        outcomesSelected.selectAllState !== ALL_SELECTED &&
        traineeIdsSelected.length === 0
      ) {
        setOutcomeTraineeList(results);
        return;
      }

      let totalPages = 0;
      do {
        page = page + 1;

        const request = {
          url: url,
          params: { ...params, p: page, ps: pageSize, vw: "summary" },
        };
        const response = await axios(request);

        if (response.data.count > 0) {
          totalPages = response.data.totalPages || 0;

          const responseData = response.data.values.filter((trainee) => {
            if (!trainee.traineeEmail) return false;

            if (outcomesSelected.selectAllState === ALL_SELECTED) return true;

            return outcomesSelected.selectAllState === NONE_SELECTED
              ? traineeIdsSelected.includes(trainee._links.self.href)
              : !traineeIdsSelected.includes(trainee._links.self.href);
          });

          results = [...results, ...responseData];
        }
      } while (page < totalPages);

      setOutcomeTraineeList(results);
    }

    // process response.data: If response is only subset of data need to get the rest of pages in a loop
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const getOutcomeEmailInvitationFields = async (
  setEmailFields,
  submissionId,
  setLoading,
  setAlert,
  clearAlert
) => {
  setLoading(true);

  try {
    clearAlert();

    if (submissionId != null) {
      // get email details
      const emailResponse = await axios({
        url: `/submissions/${submissionId}/outcomes/invitations/email`,
      });
      setEmailFields(emailResponse.data || {});
    }

    // process response.data: If response is only subset of data need to get the rest of pages in a loop
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const previewOutcomeInvitationEmail = async (
  emailValues,
  submissionId,
  setEmailPreviewMessage,
  setLoading,
  setAlert,
  clearAlert
) => {
  setLoading(true);

  const emailRequest = {
    ...(emailValues.replyToEmailAddress && {
      replyToEmailAddress: emailValues.replyToEmailAddress,
    }),
    fromEmailAddress: emailValues.fromEmailAddress,
    fromName: emailValues.fromName,
    subject: emailValues.subject,
    message: emailValues.message,
    recipients: emailValues.recipientOutcomeIds,
  };

  try {
    clearAlert();

    const emailResponse = await axios.post(
      `/submissions/${submissionId}/outcomes/invitations/preview`,
      emailRequest
    );
    setEmailPreviewMessage(emailResponse.data.previewMessage || "");
  } catch (error) {
    setAlert("error", error.message, false, false);
  } finally {
    setLoading(false);
  }
};

export const sendOutcomeInvitationEmail = async (
  outcomeTraineeList,
  emailValues,
  submissionId,
  setOpenSendInvitationDialog,
  setAlert,
  clearAlert,
  resetAllSelections,
  toggleInvitationsSent
) => {
  const outcomeIds = outcomeTraineeList.map(
    (trainee) => trainee._links.self.href.split("/").slice(-1)[0]
  );

  const emailRequest = {
    fromEmailAddress: emailValues.fromEmailAddress,
    fromName: emailValues.fromName,
    replyToEmailAddress: emailValues.replyToEmailAddress,
    subject: emailValues.subject,
    message: emailValues.message,
    recipients: outcomeIds,
  };

  try {
    clearAlert();
    const response = await axios.post(
      `/submissions/${submissionId}/outcomes/invitations`,
      emailRequest
    );

    // **** Redirect to /outcomes on Successful form Submit ****
    resetAllSelections();
    toggleInvitationsSent((prevState) => !prevState);
    setOpenSendInvitationDialog(false);
    const alertMessage = i18n.t(
      "sendInvitation.notification.emailInvitationSent",
      {
        numberOfTrainees: outcomeTraineeList.length,
      }
    );
    setAlert("success", alertMessage, true);
    return response.status;
  } catch (error) {
    setAlert("error", error.message, false, false);
  }
};
export const deleteSelectedOutcomes = async (
  submissionId,
  setOpenDeleteOutcomesDialog,
  setAlert,
  clearAlert,
  resetAllSelections,
  toggleDeleteOutcomes,
  setOutcomesPreferences,
  outcomesPreferences,
  outcomesSelected
) => {
  try {
    clearAlert();
    const { individualSelections, selectAllState } = outcomesSelected;
    const outcomeIds = Object.keys(individualSelections).map(
      (link) => link.split("/").slice(-1)[0]
    );

    const filters =
      selectAllState !== SelectAllStates.NONE_SELECTED
        ? retrieveGetOutcomesParams(outcomesPreferences)
        : {};

    const data =
      selectAllState !== SelectAllStates.NONE_SELECTED
        ? { excludedIds: outcomeIds }
        : { includedIds: outcomeIds };

    const response = await axios.delete(
      `/submissions/${submissionId}/outcomes`,
      {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        params: {
          ...filters,
        },
        data: data,
      }
    );
    if (response) {
      resetAllSelections();
      toggleDeleteOutcomes((prevState) => !prevState);
      setOpenDeleteOutcomesDialog(false);
      const alertMessage = i18n.t(
        "removedTrainees.notification.traineesRemoved",
        {
          numberOfTrainees: response.data.countDeleted ?? 0,
        }
      );
      setAlert("success", alertMessage, true);
      setOutcomesPreferences({
        ...outcomesPreferences,
        values: {
          ...outcomesPreferences.values,
          renderCount: outcomesPreferences.values.renderCount + 1,
        },
      });
    }
  } catch (error) {
    setAlert("error", error.message, false, false);
  }
};
