export const navbar = {
  width: 786,
  items: [
    {
      name: "Participating Faculty",
      menuItem: [],
      route: "/faculty",
    },
    {
      name: "Training Grants",
      menuItem: [],
      route: "/grants",
    },
    {
      name: "Trainees",
      menuItem: [],
      route: "/trainees",
    },
    {
      name: "Applicants",
      menuItem: [],
      route: "/applicants",
    },
    {
      name: "Outcomes",
      menuItem: [],
      route: "/outcomes",
    },
    {
      name: "Output",
      menuItem: [
        { name: "Table 1", route: "/output/table-1" },
        { name: "Table 2", route: "/output/table-2" },
        { name: "Table 3", route: "/output/table-3" },
        { name: "Table 4", route: "/output/table-4" },
        {
          name: "Table 6A",
          route: "/output/table-6A",
        },
        { name: "Table 6B", route: "/output/table-6B" },
        { name: "Table 8A", route: "/output/table-8A" },
        { name: "Table 8B", route: "/output/table-8B" },
      ],
    },
    {
      name: "Admin",
      menuItem: [{ name: "Manage Submissions", route: "/admin/submissions" }],
    },
  ],
};
